/* eslint-disable no-bitwise */
<template>
  <div class="page-container-table p-2">
    <validation-observer ref="rule">
      <div class="infomation-and-adress">
        <!--===============  Thông tin =================-->
        <custtom-title
          :icon="'InfoIcon'"
          :title="'Thông tin'"
        />
        <div class="infomation-and-adress__input__01">
          <b-row>
            <!--===============  Người nộp hồ sơ doanh nghiệp  =================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group>
                <label>Người nộp hồ sơ doanh nghiệp <span class="required">(*)</span></label>
                <validation-provider
                  ref="officalProfileId"
                  #default="{ errors }"
                  rules="required"
                  :custom-messages="{required: `Người nộp hồ sơ doanh nghiệp là bắt buộc`}"
                >
                  <v-select
                    v-model="dataCreateComfirm.officalProfileId"
                    :reduce="(value) => value.id"
                    label="name"
                    :state="errors.length > 0 ? false:null"
                    :options="dataOfficalProfile || []"
                    placeholder="Người nộp hồ sơ doanh nghiệp"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--===============  Người lao động =================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group>
                <label for="basicInput">Người lao động <span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="reportTypeId"
                  rules="required"
                  :custom-messages="{required: `Người lao động là bắt buộc`}"
                >
                  <v-select
                    v-model="dataCreateComfirm.workerId"
                    :reduce="title => title.id"
                    label="name"
                    :options="dataWorkerNotRegister || []"
                    :placeholder="'Người lao động'"
                    :state="errors.length > 0 ? false:null"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--===============  Đối tượng không thuộc giấy phép lao động =================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group>
                <label>Đối tượng không thuộc giấy phép lao động <span class="text-danger">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :custom-messages="{required: `Đối tượng không thuộc giấy phép lao động là bắt buộc`}"
                >
                  <b-form-input
                    v-model="dataCreateComfirm.objectNotLicense"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--===============  Số giấy đề nghị xác nhận lao động =================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group>
                <label>Số giấy đề nghị xác nhận lao động <span class="text-danger">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :custom-messages="{required: `Số giấy đề nghị xác nhận lao động là bắt buộc`}"
                >
                  <b-form-input
                    v-model="dataCreateComfirm.numberRequire"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--===============  Ngày bắt đầu =================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group>
                <label for="InputHelp">Ngày bắt đầu <span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :custom-messages="{required: `Ngày bắt đầu là bắt buộc`}"
                >
                  <date-time-picker
                    v-model="dataCreateComfirm.dateStartString"
                    :disabledInput="true"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--=============== Ngày kết thúc =================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group>
                <label for="InputHelp">Ngày kết thúc <span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :custom-messages="{required: `Ngày kết thúc là bắt buộc`}"
                >
                  <date-time-picker
                    v-model="dataCreateComfirm.dateEndString"
                    :disabledInput="true"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <!--===============  Tiền lương =================-->
        <custtom-title
          :icon="'FileTextIcon'"
          :title="'Văn bản chấp thuận sử dụng người dụng người lao động nước ngoài'"
        />
        <div class="infomation-and-adress__input__01">
          <b-row>
            <!--=============== Số văn bản=================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group>
                <label>Số văn bản <span class="text-danger">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :custom-messages="{required: `Số văn bản là bắt buộc`}"
                >
                  <b-form-input
                    v-model="dataCreateComfirm.numberUsingWorker"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--=============== Người ra văn bản=================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group>
                <label>Người ra văn bản <span class="text-danger">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :custom-messages="{required: `Người ra văn bản là bắt buộc`}"
                >
                  <b-form-input
                    v-model="dataCreateComfirm.usingWorkerCreateBy"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--=============== Ngày ra văn bản =================-->
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group>
                <label for="InputHelp">Ngày ra văn bản</label>
                <date-time-picker
                  v-model="dataCreateComfirm.dateUsingWorkerString"
                  :disabledInput="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
    </validation-observer>
    <div class="addwork__button mt-3">
      <!--=======================================Nút thêm nhóm người dùng=============================== -->
      <div class="addwork__button__item mr-2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="handleCreate"
        >
          Lưu lại
        </b-button>
      </div>
      <!--=====================Nút hủy bỏ nhóm người dùng============================== -->
      <div class="addwork__button__item">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="$router.push({ name: 'work-permit' })"
        >
          Quay về
        </b-button>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BRow, BCol, BButton, BFormGroup, BFormInput,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import CusttomTitle from '@/views/management-business/business/pages/components/InformationAndAdressTitle.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    DateTimePicker,
    BFormInput,
    BFormGroup,
    CusttomTitle,
    BRow,
    BCol,
    vSelect,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      dataCreateComfirm: {
        workerId: '',
        officalProfileId: '',
        numberUsingWorker: '',
        dateUsingWorkerString: '',
        usingWorkerCreateBy: '',
        dateStartString: '',
        dateEndString: '',
        numberRequire: '',
        objectNotLicense: '',
      },
      dataDetailComfirm: {},
      workerComfirmId: '',
    }
  },
  watch: {
    dataDetailComfirm(val) {
      if (val) {
        this.dataCreateComfirm = {
          workerId: this.dataDetailComfirm.workerId,
          officalProfileId: this.dataDetailComfirm.officalProfileId,
          numberUsingWorker: this.dataDetailComfirm.numberUsingWorker,
          dateUsingWorkerString: this.dataDetailComfirm.dateUsingWorkerString,
          usingWorkerCreateBy: this.dataDetailComfirm.usingWorkerCreateBy,
          dateStartString: this.dataDetailComfirm.dateStartString,
          dateEndString: this.dataDetailComfirm.dateEndString,
          numberRequire: this.dataDetailComfirm.numberRequire,
          objectNotLicense: this.dataDetailComfirm.objectNotLicense,
        }
      }
    },
  },
  computed: {
    ...mapGetters('workerPermit', ['dataOfficalProfile', 'dataWorkerNotRegister']),
  },
  mounted() {
    this.fetchDataComboboxProfile()
    if (!this.$route.params.id) {
      this.fetchDataComboboxWorker()
    }
  },
  created() {
    if (this.$route.params.id) {
      this.workerComfirmId = this.$route.params.id
      this.fetchDataDetail(this.workerComfirmId)
    }
  },
  methods: {
    ...mapActions('workerPermit', ['fetchDataComboboxProfile', 'fetchDataComboboxWorker']),

    // Lấy dữ liệu chi tiết
    async fetchDataDetail() {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_WORKER_COMFIRM}${this.workerComfirmId}`)
      this.dataDetailComfirm = data
      await this.fetchDataComboboxWorker(this.dataDetailComfirm.workerId)
    },

    handleCreate() {
      const datestart = new Date(this.dataCreateComfirm.dateStartString).getTime()
      const dateEnd = new Date(this.dataCreateComfirm.dateEndString).getTime()

      if (datestart > dateEnd) {
        this.$root.$bvToast.toast('Ngày kết thúc không nhỏ hơn ngày bắt đầu', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      } else {
        this.save()
      }
    },

    // Thêm hoặc sửa xác nhận giấy phép
    async save() {
      this.$refs.rule.validate().then(async success => {
        if (success) {
          const data = {
            ...this.dataCreateComfirm,
          }
          this.$showAllPageLoading()
          if (this.$route.params.id) {
            await axiosApiInstance.put(ConstantsApi.EDIT_REQURIE_COMFIRM, { ...data, id: this.$route.params.id }).then(res => {
              if (res.status === 200) {
                this.$hideAllPageLoading()
                this.$root.$bvToast.toast('Sửa thành công', {
                  title: 'Thông báo',
                  variant: 'success',
                  toaster: this.$toastPosition,
                  solid: true,
                })
                this.$router.push({ name: 'work-permit' })
              } else {
                this.$hideAllPageLoading()
                this.$root.$bvToast.toast(`${res.data?.errors[0]}`, {
                  title: 'Thông báo',
                  variant: 'danger',
                  toaster: this.$toastPosition,
                  solid: true,
                })
              }
            })
          } else {
            this.$showAllPageLoading()
            await axiosApiInstance.post(ConstantsApi.CREATE_REQUIRE_COMFIRM, data).then(res => {
              if (res.status === 200) {
                this.$hideAllPageLoading()
                this.$root.$bvToast.toast('Tạo thành công', {
                  title: 'Thông báo',
                  variant: 'success',
                  toaster: this.$toastPosition,
                  solid: true,
                })
                this.$router.push({ name: 'work-permit' })
                this.listTabs[1].isDisabled = false
                this.listTabs[2].isDisabled = false
              } else {
                this.$hideAllPageLoading()
                this.$root.$bvToast.toast(`${res.data?.errors[0]}`, {
                  title: 'Thông báo',
                  variant: 'danger',
                  toaster: this.$toastPosition,
                  solid: true,
                })
              }
            })
          }
        }
      })
    },
  },
}
</script>

<style >
.addwork__button{
    display: flex;
}
</style>
